<template>
    <LiefengContent>
        <template v-slot:title>广州市52个示范社区服务情况</template>
        <template v-slot:toolsbarRight>
            <DatePicker v-model="yearOfMonth" type="month" transfer placeholder="请选择日期" style="width: 200px" format="yyyy-MM" @on-change="changeDate" />
            <LiefengCascaderCity v-if="showCascader" @changeCasader="changeCasader" :resetNum="resetNum" :orgCode="4401" :width="200" :isClear="true"></LiefengCascaderCity>
            <Button type="primary" @click="getList()">查询</Button>
            <Button type="info" @click="resetBtn">重置</Button>
        </template>
        <template v-slot:contentArea>
            <div class="content-div">
                <div v-for="(item,index) in tableData" :key="index">
                    <div class="title-div">
                        {{item.zone || '未知'}}
                        <span>{{ item.list.length || 0 }}</span>
                    </div>
                    <div class="org-div">
                        <Card class="item" v-for="(items,indexs) in item.list" :key="indexs">
                            <div class="num">{{ items.dataValue + "次" || '0次' }}</div>
                            <div class="text">{{ items.community || '未知' }}</div>
                        </Card>
                    </div>
                </div>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
export default {
    components: {
        LiefengContent,
        LiefengCascaderCity
    },
    data() {
        return {
            //表头列表
            tableData: [],
            yearOfMonth: "",
            showCascader: true,
            cascaderList:[],
            resetNum: 1, // 是否清空当前社区选择
        }
    },
    created() {
        this.getList()
    },
    methods: {
        changeCasader(val) {
            this.cascaderList = val
        },
        // 重置
        resetBtn() {
            this.resetNum++
            this.cascaderList = []
            this.yearOfMonth = ""
            this.getList()
        },
        // 修改时间
        changeDate(val) {
            this.yearOfMonth = this.$core.formatDate(new Date(val), "yyyy-MM")
        },
        getList() {
            this.$Message.loading({
                content:'正在加载数据，请稍等'
            })
            this.$get("/gateway/api/symanage/pc/o2omin/getOrgCount", {
                month: this.yearOfMonth && this.yearOfMonth != "" ? this.$core.formatDate(new Date(this.yearOfMonth), "yyyy-MM") : "",
            }).then(res => {
                this.$Message.destroy()
                if (res && res.data && res.data.length) {
                    if(this.cascaderList && this.cascaderList.length){
                        let code = this.cascaderList[this.cascaderList.length - 1]
                        let data = []
                        res.data.map(item =>{
                            if(item.zoneCode == code || item.streetCode == code || item.communityCode == code){
                                data.push(item)
                            }   
                        })
                        console.log('筛选的数据',data);
                        this.tableData = this.formattingData(data,'zone')
                    }else{
                        this.tableData = this.formattingData(res.data, "zone")
                    }   
                    
                } else {
                    this.tableData = []
                }
            })
        },
        formattingData(arr, group_key) {
            // 先定义一个空对象和空数组；
            let map = {}
            let res = []
            // 循环需要筛选的数组
            for (let i = 0; i < arr.length; i++) {
                let ai = arr[i]
                // 将需要筛选的属性的值作为新对象的键，并且判断是否已经存在
                if (!map[ai[group_key]]) {
                    // 不存在的话就在map对象中创建一个属性的值作为键名，键值为空数组的新对象，并且把arr[i]放入
                    map[ai[group_key]] = [ai]
                } else {
                    // 如果已经存在就直接把arr[i]放入
                    map[ai[group_key]].push(ai)
                }
            }
            // 循环后对map进行处理生成分组的数组
            Object.keys(map).forEach(key => {
                res.push({
                    [group_key]: key,
                    list: map[key],
                })
            })

            return res
        },
    },
}
</script>
    
<style scoped lang='less'>
.content-div {
    .title-div {
        margin: 10px 20px -16px 20px;
        font-size: 18px;
        font-weight: bold;
        line-height: 40px;
        color: #287aad;
        span {
            margin-left: 5px;
        }
    }
    .org-div {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .item {
            width: 10%;
            margin: 10px 20px;
            text-align: center;
            padding: 10px;
            .num {
                font-weight: bold;
                font-size: 18px;
                text-decoration: underline;
                margin-bottom: 5px;
            }
            .text {
                font-size: 18px;
            }
        }
    }
}
</style>